<template>
	<PageLayoutVC v-bind:p_instanceId="'id_141'" v-bind:p_vueDomain="c_vueDomain+'.'+'PageLayoutVC'"><template v-slot:slot_main><hr>
		<center>
			{{message}}
		</center>
	<hr>
	</template></PageLayoutVC>
</template>

<script>
	// <script lang="ts">
	import $ from 'jquery';
	// see de.vdoemming.blog.vue.components.common.StatusVC
	import PageLayoutVC from './pagelayoutvc.vue'
	export default {
		props: {
			p_vueDomain: {
						type: String,
						required: false
					},
			ddeebbuugg: {
						type: Object,
						required: false,
						default: {}
					},
			p_instanceId: {
						type: String,
						required: false
					}
		}
		,
		data() {
			return {"d_userLanguage":"de","d_i18nLocalDB":{},"message":null}
		}
		,
		computed: {
			c_vueDomain() {
				if(this.p_vueDomain==undefined){
					return 'StatusVC'
				}else{
					return this.p_vueDomain
				}
			}
			,
			console() {
				return console
			}
		}
		,
		components: {
			PageLayoutVC
		}
		,
		methods: {
			i18n: function(key){
				// check local
				const langObject=this.d_i18nLocalDB[key]
				if(langObject){
					const text=langObject[this.d_userLanguage]
					if(text){
						return text
					}
				}				
				// check global
				var result=this.globalI18nDict[key];
				if(result===undefined){
					return key
				}else{
					return result
				}
			}
		}
		,
		mounted() {
			const thisVue=this
			
			console.log('mounted component on vue domain: '+this.c_vueDomain)
			if(this.$el.parentElement.attributes['message']){
				this.message=this.$el.parentElement.attributes['message'].value
			}
		}
		,
		provide() {
			return {}
		}
	}
</script>
